<template>
    <Head :title="`Registro realizado con exito!`" />
    <div class="flex items-center justify-center p-10 min-h-screen bg-gray-300">
      <div class="w-full max-w-lg">
        <div class="flex justify-center">
          <logo :src="logo" class="fill-white" width="200" height="50" />
        </div>
        <h1 class="text-center">Representante registrado con exito!, pronto recibirá noticias...</h1>
      </div>
    </div>
  </template>
  
  <script>
  import { Head } from '@inertiajs/inertia-vue3'
  import Logo from '@/Shared/Logo'
  import TextInput from '@/Shared/TextInput'
  import LoadingButton from '@/Shared/LoadingButton'
  import SelectInput from '../Shared/SelectInput.vue'
  
  export default {
    components: {
      Head,
      LoadingButton,
      Logo,
      TextInput,
      SelectInput,
    },
    props: {
      streets: Object,
      account: Object,
      logo: String
    },
    data() {
      return {
        form: this.$inertia.form({
          identification: '',
          companyName: '',
          name: '',
          phone: '',
          address: '',
          street_id: '',
        }),
      }
    },
    methods: {
      register() {
        this.form.post('/registerClient')
      },
    },
  }
  </script>
  