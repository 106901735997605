<template>
    <Head title="Consultar estado de cuenta" />
    <div class="flex items-center justify-center p-2 min-h-screen bg-gray-300">
      <div class="w-full max-w-md">
        <logo class="block mx-auto w-full max-w-xs fill-white" height="30" />
        <form class="mt-8 bg-white rounded-lg shadow-xl overflow-hidden" @submit.prevent="login">
          <div class="px-10 py-12">
            <h1 class="text-center text-xl font-bold">Consulta estado de cuenta</h1>
            <div class="mt-6 mx-auto w-24 border-b-2" />
            <text-input v-model="form.identification" :error="form.errors.identification" class="mt-10" label="Cedula" type="text" autofocus autocapitalize="off" />
          </div>
          <div class="flex px-10 py-4 bg-gray-100 border-t border-gray-100">
            <loading-button :loading="form.processing" class="btn-blue ml-auto" type="submit">Buscar</loading-button>
          </div>
        </form>
      </div>
    </div>
  </template>
  
  <script>
  import { Head } from '@inertiajs/inertia-vue3'
  import Logo from '@/Shared/Logo'
  import TextInput from '@/Shared/TextInput'
  import LoadingButton from '@/Shared/LoadingButton'
  
  export default {
    components: {
      Head,
      LoadingButton,
      Logo,
      TextInput,
    },
    data() {
      return {
        form: this.$inertia.form({
            identification: ''
        }),
      }
    },
    methods: {
      login() {
        this.form.post('/findClient')
      },
    },
  }
  </script>
  