<template>
  <div>
    <Head title="Ordenes" />
    <div class="flex items-center justify-between mb-6">
      <button class="mr-2 p-2 text-white bg-teal-800 rounded" @click="back()">
        <i class="fa fa-arrow-left"></i>
      </button>
      <h1 class="mb-8 text-3xl font-bold">Cobranza</h1>
    </div>
    <div class="flex items-center justify-between mb-6">
      <search-filter v-model="form.search" class="mr-4 w-full max-w-md" @reset="reset"> </search-filter>
      <div>
        <Link v-if="user.role === 'sales' || user.role === 'admin'" target="_blank" :href="`/orders/create`" class="btn-blue mr-2"> Crear cobranza</Link>
        <a :href="`/ordersDaily`" target="_blank" class="btn-blue bg-red-900 mr-2"><i class="fa fa-print"></i> Reporte diario </a>
        <Link v-if="user.role === 'financial' || user.role === 'admin'" target="_blank" :href="`/ordersReportFinancial`" class="btn-blue bg-green-500 mr-2"> Finanzas</Link>

      </div>
    </div>
    <div class="bg-white rounded-md shadow overflow-x-auto">
      <table class="w-full whitespace-nowrap">
        <tr class="text-left font-bold">
          <th class="pb-4 pt-6 px-6">#</th>
          <th class="pb-4 pt-6 px-6">Fecha</th>
          <th class="pb-4 pt-6 px-6">Estado</th>
          <th class="pb-4 pt-6 px-6">Descripcion</th>
          <th class="pb-4 pt-6 px-6">Representante</th>
          <th class="pb-4 pt-6 px-6">Estudiante</th>
          <th class="pb-4 pt-6 px-6">Total</th>
        </tr>
        <tr v-for="order in orders.data" :key="order.id" :class="`hover:bg-gray-100 focus-within:bg-gray-100 ${order.order_state == 'cancel' ? 'bg-red-200' : ''}`">
          <td class="border-t">
            <Link :href="`orders/${order.id}`" class="flex items-center px-6 py-4 focus:text-indigo-500">
              {{ order.number_order }}
            </Link>
          </td>
          <td class="border-t">
            <Link :href="`orders/${order.id}`" class="flex items-center px-6 py-4 focus:text-indigo-500">
              {{ order.date }}
            </Link>
          </td>
          <td class="border-t">
            <Link :href="`orders/${order.id}`" class="flex items-center px-6 py-4 focus:text-indigo-500">
              <span v-if="order.order_state == 'cancel'">ANULADA</span>
              <span v-if="order.order_state == 'request'">PENDIENTE POR PAGO</span>
              <span v-if="order.order_state == 'finish'">FINALIZADA</span>
              <span v-if="order.order_state == 'pay'">PAGADA</span>
              <span v-if="order.order_state == 'delivery'">EN ESPERA DE DESPACHO</span>
            </Link>
          </td>
          <td class="border-t">
            <span v-for="prod in order.products" :key="prod.id">
              {{prod.product.name}},
            </span>
          </td>
          <td class="border-t">
            <Link :href="`orders/${order.id}`" class="flex items-center px-6 py-4 focus:text-indigo-500">
              {{ order.client.name }}
            </Link>
          </td>
          <td class="border-t">
            <Link :href="`orders/${order.id}`" class="flex items-center px-6 py-4 focus:text-indigo-500">
              {{ order?.student?.name }}
            </Link>
          </td>
          <td class="border-t">
            <Link :href="`orders/${order.id}`" class="flex items-center px-6 py-4 focus:text-indigo-500"> ${{ order.total }} </Link>
          </td>
        </tr>
        <tr v-if="!orders">
          <td class="px-6 py-4 border-t" colspan="4">No hay cobranzas.</td>
        </tr>
      </table>
    </div>
    <pagination class="mt-6" :links="orders.links" />
  </div>
</template>

<script>
import { Head, Link } from '@inertiajs/inertia-vue3'
import Icon from '@/Shared/Icon'
import Layout from '@/Shared/Layout'
import { getState } from '@/utils'
import Pagination from '@/Shared/Pagination.vue'
import SearchFilter from '@/Shared/SearchFilter.vue'
import throttle from 'lodash/throttle'
import pickBy from 'lodash/pickBy'

export default {
  components: {
    SearchFilter,
    Head,
    Icon,
    Link,
    Pagination,
  },
  layout: Layout,
  data() {
    return {
      form: {
        search: '',
      },
      states: [
        {
          key: 'request',
          name: 'Solicitud',
        },
        {
          key: 'estimate',
          name: 'Presupuesto',
        },
        {
          key: 'pay',
          name: 'Pago',
        },
        {
          key: 'payRevition',
          name: 'Pago en revision',
        },
        {
          key: 'dispatch',
          name: 'Despachado',
        },
        {
          key: 'delivery',
          name: 'Liberado',
        },
        {
          key: 'finish',
          name: 'Finalizado',
        },
      ],
      getStateName(state) {
        return getState(state)
      },
    }
  },
  props: {
    orders: Object,
    user: Object,
  },
  watch: {
    form: {
      deep: true,
      handler: throttle(function () {
        this.$inertia.get('/orders', pickBy(this.form), { preserveState: true })
      }, 150),
    },
  },
  methods: {
    reset() {},
    back() {
      window.history.back()
    },
  },
}
</script>
