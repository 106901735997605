<template>
  <div class="flex flex-col items-center p-4 bg-white border-2 rounded">
    <img :src="product.photo" width="100" height="100" alt="Producto" />
    <h3 class="text-lg font-medium">{{ product.code }} {{ product.name }} </h3>
    <p class="text-gray-500">${{ product?.prices[0]['price'] }}</p>
    <p class="text-gray-500">BsD.{{ product?.prices[0]['price'] * change }}</p>
    <p class="text-gray-500">{{ product?.quantity }} disponibles</p>
    <button @click="addProduct(product)" class="px-4 py-2 text-white font-bold bg-blue-500 hover:bg-blue-700 rounded">Agregar al carrito</button>
  </div>
</template>

<script>
export default {
  props: {
    product: Object,
    change: Number
  },
  methods: {
    addProduct(product)
    {
        this.$emit('addCart', product);
    }
  }
}
</script>
