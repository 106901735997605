<template>
  <div class="flex items-center justify-center p-2 min-h-screen bg-gray-300">
    <div>
      <Head :title="`Cuota ${order.date}`" />

      <div class="flex justify-between">
        <div>
          <button class="mr-2 p-2 text-white bg-teal-800 rounded" @click="back()">
            <i class="fa fa-arrow-left"></i>
          </button>
          <h1 class="mb-8 text-3xl font-bold">Cuota {{ order.date }}</h1>
        </div>

        <div>
          <a target="_blank" :href="`/orders/${order.id}/report-storer-external`" class="btn-blue p-2 rounded"> <i class="fa fa-print text-white" /> Aviso de cobro </a>
        </div>
      </div>

      <div class="flex justify-between mb-5 p-5 bg-white rounded-md shadow overflow-x-auto">
        <div>
          <div class="pb-2">Representante: {{ order.client.name }}</div>
          <div class="pb-2">Alias o Cédula: {{ order.client.identification }}</div>
          <div class="pb-2">Email: {{ order.client.email }}</div>
          <div class="pb-2">Telefono: {{ order.client.phone }}</div>
          <div class="pb-2"><strong>Balance:</strong> ${{ order.balance }}</div>
          <div class="pb-2"><strong>Balance Bs:</strong> {{ order.balance * change }}</div>
          <div class="pb-2"><strong>Deuda:</strong> ${{ order.total - order.balance }}</div>
          <div class="pb-2"><strong>Deuda Bs:</strong> {{ (order.total - order.balance) * change }}</div>
          <div class="pb-2"><strong>Total:</strong> ${{ order.total }}</div>
          <div class="pb-2"><strong>Total Bs:</strong> {{ order.total * change }}</div>
          <div class="pb-2">
            <strong>Estado: </strong>
            <span v-if="order.order_state == 'cancel'">ANULADA</span>
            <span v-if="order.order_state == 'request'">PENDIENTE POR PAGO</span>
            <span v-if="order.order_state == 'finish'">CUOTA PAGADA</span>
            <span v-if="order.order_state == 'pay'">PAGADA</span>
            <span v-if="order.order_state == 'delivery'">EN ESPERA DE DESPACHO</span>
            <span v-if="order.order_state == 'payRevition'">Pago en revisión</span>
          </div>
        </div>
      </div>

      <div class="p-5 bg-white rounded-md shadow overflow-x-auto">
        <h2 class="mb-8 text-xl font-bold">Gastos</h2>
        <table class="w-full whitespace-nowrap">
          <thead>
            <tr>
              <th class="pb-4 pt-6 px-6">Producto</th>
              <th>Cantidad</th>
              <th class="pb-4 pt-6 px-6">Precio</th>
              <th class="pb-4 pt-6 px-6">Total</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(product, key) in order.products" :key="key" :class="`${product.dispatch ? 'bg-blue-300' : ''}`">
              <td class="p-2 text-center border-t">
                {{ product.product.name }}
              </td>
              <td class="p-2 text-center border-t">
                {{ product.quantity }}
              </td>
              <td class="p-2 text-center border-t">${{ product.price }}</td>
              <td class="p-2 text-center border-t">${{ Math.round(product.price * product.quantity) }}</td>
            </tr>
          </tbody>
          <tfoot class="px-6 py-2" style="background-color: #f3f3f3">
            <tr>
              <td class="p-2 text-center border-t"></td>
              <td class="p-2 text-center border-t"></td>
              <td class="p-2 text-center border-t"><strong>Total</strong></td>
              <td class="p-2 text-center border-t">${{ order.total }}</td>
            </tr>
          </tfoot>
        </table>
        <button v-if="order.order_state == 'delivery'" @click="addDispatch" class="text-md my-2 p-2 text-white bg-teal-800 rounded">Despachar productos</button>
      </div>

      <div v-if="(role == 'financial' || role == 'admin' || role == 'sales') && order.order_state != 'cancel'" class="mt-5 mb-5 p-5 bg-white shadow">
      <h2 class="mb-8 text-xl font-bold">Pagos</h2>
      <div class="pt-2">
        <div class="pb-8 pr-6 w-full">
          <label for="">Metodo de pago</label>
          <select v-model="form.method" class="form-input mt-2 w-full">
            <option value="">Seleccionar</option>
            <option value="pago-movil">Pago móvil Bs</option>
            <option value="zelle">Zelle</option>
            <option value="transferencia">Transferencia</option>
          </select>
        </div>
        <text-input v-if="form.method == 'pago-movil'" v-model="amountBs" type="number" step="0.10" :error="form.errors.long" class="pb-8 pr-6 w-full" label="Monto Bs" />
        <text-input :disabled="form.method == 'pago-movil'" v-model="form.amount" type="number" step="0.10" :error="form.errors.long" class="pb-8 pr-6 w-full" label="Monto" />
        <text-input v-if="form.method === 'transferencia' || form.method === 'transferencia-usd' || form.method === 'zelle' || form.method === 'pago-movil' || form.method === 'punto-venta'" v-model="form.reference" :error="form.errors.reference" class="pb-8 pr-6 w-full" label="Referencia" />
        <text-input type="date" v-model="form.date" :error="form.errors.date" class="pb-8 pr-6 w-full" label="Fecha del pago" />

        <button @click="addPay" class="text-md my-2 p-2 text-white bg-teal-800 rounded">Agregar pagos</button>
      </div>
      <div>
        <table class="w-full whitespace-nowrap">
          <thead>
            <tr>
              <th class="pb-4 pt-6 px-6">Metodo</th>
              <th class="pb-4 pt-6 px-6">Monto</th>
              <th class="pb-4 pt-6 px-6">Fecha</th>
              <th class="pb-4 pt-6 px-6">Referencia</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="pay in order.pays" :key="pay.id" class="hover:bg-gray-100 focus-within:bg-gray-100">
              <td class="p-2 text-center border-t capitalize">
                {{ pay.method }}
              </td>
              <td class="p-2 text-center border-t">
                {{ pay.amount }}
              </td>
              <td class="p-2 text-center border-t">
                {{ pay.date ?? pay.created_at }}
              </td>
              <td class="p-2 text-center border-t">
                {{ pay.reference }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

      <!-- <div v-if="order.pays.length == 0" class="mt-5 p-5 text-right bg-white shadow">
    <button v-if="order.order_state != 'cancel'" @click="cancelOrder()" class="text-md p-2 text-white bg-red-500 rounded">Anular Orden</button>
  </div> -->

      <!-- <pagination class="mt-6" :links="vendors.links" /> -->
    </div>
  </div>
</template>

<script>
import { Head, Link } from '@inertiajs/inertia-vue3'
import Layout from '@/Shared/Layout'
import TextInput from '@/Shared/TextInput.vue'

export default {
  components: {
    Head,
    Link,
    TextInput,
  },
  props: {
    order: Object,
    dispatches: Object,
    change: Number
  },
  watch: {
    'form.method' : function(val)
    {
      this.clean();
    },
    'amountBs' : function(val) 
    {
        this.form.amount = val / this.change;
    }
  },
  data() {
    return {
      amountBs: 0,
      role: 'financial',
      form: this.$inertia.form({
        amount: 0,
        method: '',
        order_id: this.order.id,
        reference: '',
        date: ''
      }),
      products: this.$inertia.form({
        products: this.order.products,
        order_id: this.order.id,
      }),
    }
  },
  methods: {
    back() {
      window.history.back()
    },
    cancelOrder() {
      if (confirm('Estas seguro que quieres anular esta orden?')) {
        window.location.href = `/orders/${this.order.id}/cancel`
      }
    },
    addDispatch() {
      this.products.post('/orders/dispatch')
    },
    addPay() {
      if (this.form.method === 'transferencia' || this.form.method === 'transferencia-usd' || this.form.method === 'zelle') {
        if (!this.form.reference) {
          alert('Debe ingresar una referencia')
          return false
        }
      }
      if (this.order.total < parseInt(this.form.amount) + parseInt(this.order.balance)) {
        return alert('No se puede agregar este pago si el monto es mayor al balance')
      }
      this.form.order_id = this.order.id
      this.form.post('/orders-external/pay')
      console.log(this.form)

      this.cleanPay()
    },
    cleanPay() {
      this.form = this.$inertia.form({
        amount: 0,
        method: '',
        order_id: this.order.id,
        reference: '',
      })
    },
  },
}
</script>
