<template>
  <div>
    <h1 class="mb-8 text-3xl font-bold">Rellena la informacion de Representante</h1>
    <Head title="Crear Venta" />
    <div class="bg-white rounded-md shadow overflow-hidden">
      <div class="p-5">
        <div>
          <text-input v-model="form.client.identification" label="Cedula de Representante" class="pb-3 pr-6 w-full lg:w-1/2" />
          <button class="btn-blue" href="/orders/create" @click="findClient">
            {{ textSearch }} <i class="fa fa-search"/>
          </button>
        </div>
        <!-- Formulario de Representante -->
        <div v-if="showClientCreate" class="flex flex-wrap -mb-8 -mr-6 p-8">
          <text-input v-model="form.client.name" label="Nombre y Apellido de Representante" class="pb-8 pr-6 w-full lg:w-1/2" />
          <text-input v-model="form.client.email" label="Email de Representante" class="pb-8 pr-6 w-full lg:w-1/2" />
          <text-input v-model="form.client.identification" label="Cedula de Representante" class="pb-8 pr-6 w-full lg:w-1/2" />
          <text-input v-model="form.client.phone" label="Telefono de Representante" class="pb-8 pr-6 w-full lg:w-1/2" />
          <text-input v-model="form.client.address" label="Direccion de Representante" class="pb-8 pr-6 w-full lg:w-1/2" />
          <text-input v-model="form.comentary" label="Observación" class="pb-8 pr-6 w-full lg:w-1/2" />
          <label for="">Metodo de pago</label>
          <select v-model="form.method" class="form-input mt-2 w-full">
            <option value="">Seleccionar</option>
            <option value="efectivo">Efectivo</option>
            <option value="transferencia">Transferencia Bs</option>
            <option value="transferencia-usd">Transferencia Divisas</option>
            <option value="biopago">Zelle</option>
            <option value="credito">Credito</option>
          </select>
          <text-input v-if="form.method && form.method !== 'efectivo'" v-model="form.referencePay" label="Referencia" class="mt-2 w-full" />
          <button class="btn-blue mt-5" @click="generateOrder">
            Siguiente
          </button>
        </div>


      </div>
    </div>
  </div>
</template>

<script>
import TextInput from '@/Shared/TextInput'
import { Head } from '@inertiajs/inertia-vue3'
import pickBy from 'lodash/pickBy'
import Layout from '@/Shared/Layout.vue'
export default {
  name: 'CreateClient',
  components: {
    TextInput,
    Head,
  },
  layout: Layout,
  props: {
    client:Object
  },
  data() {
    return {
      form: this.$inertia.form({
        client: {
          name: '',
          email: '',
          cedula: '',
          telefono: '',
          address: '',
          identification: '',
        },
      }),
      textSearch: 'Buscar Representante',
      showClientCreate: false,
    }
  },
  methods: {
    findClient() {
      this.$inertia.get('/orders/client', pickBy(this.form), { preserveState: true })
      this.textSearch = 'Buscando...'

      setTimeout(() => {
        if (this.client) {
          this.textSearch = 'Buscar Representante'
          this.showClientCreate = true
          this.form = this.$inertia.form({
            client: {
              name: this.client.name,
              email: this.client.email,
              phone: this.client.phone,
              address: this.client.address,
              identification: this.client.identification,
            },
            search: {
              terms: "",
              deposit: "",
              brand_id: "",
              part_id: "",
              truck_model_id: ""
            },
            comentary: '',
            products: [],
            method: '',
            referencePay: '',
          })
        } else {
          this.textSearch = 'Buscar Representante'
          alert('No se encontro Representante, puede crearlo en el siguiente formulario')
          this.showClientCreate = true
        }
      }, 1500)
    },
    generateOrder() {
      this.form.post('/orders')
    }
  }
}
</script>

<style scoped>

</style>
