<template>
  <div>
    <Head title="Dashboard" />
    <h1 class="mb-8 text-3xl font-bold">Escritorio</h1>

    <div v-if="role == 'admin'" class="grid grid-cols-1 gap-4 md:grid-cols-4">
      <box-dashboard :title="'Gastos del mes'">
        <p class="text-center text-3xl font-black">
          ${{ spentTotal.toFixed(2) }}
        </p>
      </box-dashboard>
      <box-dashboard :title="'Cuentas por cobrar'">
        <p class="text-center text-3xl font-black">
          {{ orderTotal.toFixed(2) }}
        </p>
      </box-dashboard>
      <box-dashboard v-if="role == 'financial' || role == 'admin'" :title="'Recaudado'">
        <p class="text-center text-3xl font-black">${{ balanceTotal.toLocaleString('es') }}</p>
      </box-dashboard>
      <box-dashboard :title="'Total de Representantes'">
        <p class="text-center text-3xl font-black">
          {{ clientTotal.toLocaleString('es') }}
        </p>
      </box-dashboard>
    </div>
  </div>
</template>

<script>
import { Head, Link } from '@inertiajs/inertia-vue3'
import Layout from '@/Shared/Layout'
import BoxDashboard from '../../Shared/BoxDashboard.vue'

export default {
  components: {
    Link,
    Head,
    BoxDashboard,
  },
  layout: Layout,
  data() {
    return {
      role: this.$parent.auth.user.role,
    }
  },
  props: {
    total: Number,
    orders: Object,
    clientTotal: Number,
    balanceTotal: Number,
    products: Number,
    orderTotal: Number,
    spentTotal: Number,
    productsSoldout: Object,
    orderMonthTotal: Number
  },
  methods: {
    getDate(date) {
      let dateNew = new Date(date)
      return dateNew.toLocaleString('es-VE', { hour12: false })
    },
  },
}
</script>
