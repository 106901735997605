<template>
  <div>
    <Head title="Editar producto" />
      <button class="bg-teal-800 text-white rounded p-2 mr-2" @click="back()">
        <i class="fa fa-arrow-left"></i>
      </button>
    <h1 class="mb-8 text-3xl font-bold">

      <Link class="text-indigo-400 hover:text-indigo-600" href="/products">Productos</Link>
      <span class="text-indigo-400 font-medium">/</span>
      <Link class="text-indigo-400 hover:text-indigo-600" :href="`/products/${product.id}`">{{ form.name }}</Link>
      <span class="text-indigo-400 font-medium">/</span>
      Editar
    </h1>
    <div class="max-w-3xl bg-white rounded-md shadow overflow-hidden">
      <form @submit.prevent="update">
        <div class="flex flex-wrap -mb-8 -mr-6 p-8">
          <text-input v-model="form.code" :errors="form.errors.code" class="pb-8 pr-6 w-full lg:w-1/2" label="Codigo" />
          <text-input v-model="form.name" :errors="form.errors.name" class="pb-8 pr-6 w-full lg:w-1/2" label="Nombre" />
          <text-input v-model="form.description" :errors="form.errors.description" class="pb-8 pr-6 w-full lg:w-1/2" label="Descripcion" />
          <!-- <text-input v-model="form.line_name" :error="form.errors.line_name" class="pb-8 pr-6 w-full lg:w-1/2" label="Nombre de linea" /> -->
          <!-- <text-input v-model="form.alias" :error="form.errors.alias" class="pb-8 pr-6 w-full lg:w-1/2" label="Alias" /> -->
          <text-input
            v-for="(group, key) in groups"
            :key="group.id"
            v-model.number="form.prices[key]"
            type="numeric"
            :errors="form.errors.price"
            class="pb-8 pr-6 w-full lg:w-1/2"
            :label="`Precio ${group.name}`"
          />
          <text-input v-model="form.cost" :error="form.errors.cost" class="pb-8 pr-6 w-full lg:w-1/2" label="Costo" />
          <select-input v-model="form.brand_id" class="pb-8 pr-6 w-full lg:w-1/2" label="Marca">
            <option value="">Seleccionar</option>
            <option v-for="(brand, key) in brands" :key="key" :value="brand.id">{{ brand.name }}</option>
          </select-input>

          <!-- <select-input v-model="form.part_id" class="pb-8 pr-6 w-full lg:w-1/2" label="Parte">
            <option value="">Seleccionar</option>
            <option v-for="(part, key) in parts" :key="key" :value="part.id">{{ part.name }}</option>
          </select-input> -->

          <select-input v-model="form.contable" :error="form.errors.contable" class="pb-8 pr-6 w-full lg:w-1/2" label="Contable">
            <option value="">Seleccionar</option>
            <option value="0">No</option>
            <option value="1">Si</option>
          </select-input>
          <file-input v-model="form.photo" :error="form.errors.photo" class="pb-8 pr-6 w-full lg:w-1/2" type="file" accept="image/*" label="Photo" />
        </div>
        <div class="flex items-center px-8 py-4 bg-gray-50 border-t border-gray-100">
          <button class="text-red-600 hover:underline" tabindex="-1" type="button" @click="destroy">Eliminar producto</button>
          <loading-button :loading="form.processing" class="btn-blue" @submit="update">Actualizar producto</loading-button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { Head, Link } from '@inertiajs/inertia-vue3'
import Layout from '@/Shared/Layout'
import TextInput from '@/Shared/TextInput'
import SelectInput from '@/Shared/SelectInput'
import LoadingButton from '@/Shared/LoadingButton'
import FileInput from '@/Shared/FileInput'

export default {
  components: {
    Head,
    Link,
    LoadingButton,
    SelectInput,
    TextInput,
    FileInput
  },
  layout: Layout,
  remember: 'form',
  props: {
    product: Object,
    groups: Object,
    brands: Object,
    parts: Object
  },
  data() {
    return {
      form: this.$inertia.form({
        _method: 'put',
        code: this.product.code,
        id: this.product.id,
        name: this.product.name,
        line_name: this.product.line_name,
        description: this.product.description,
        volumen: this.product.volumen,
        weight: this.product.weight,
        prices: [
          this.product?.prices[0]['price']
        ],
        brand_id: this.product.brand_id,
        part_id: this.product.part_id,
        alias: this.product.alias,
        contable: this.product.contable,
        cost: this.product.cost,
        photo: null
      }),
    }
  },
  methods: {
    back() {
      window.history.back();
    },
    update() {
      this.form.post(`/products/${this.form.id}`)
    },
    destroy() {
      if (confirm('Estas seguro que quieres eliminar este producto?')) {
        this.$inertia.delete(`/products/${this.product.id}`)
      }
    },
  },
}
</script>
