<template>
    <div>
      <Head title="Tasas de cambio" />
      <h1 class="mb-8 text-3xl font-bold">Tasas de cambio</h1>
      <div class="flex items-center justify-between mb-6">
        <Link class="btn-blue" href="/taxes/create">
          <span>Crear</span>
          <span class="hidden md:inline">&nbsp;Tasa de cambio</span>
        </Link>
      </div>
      <div class="bg-white rounded-md shadow overflow-x-auto">
        <table class="w-full whitespace-nowrap">
          <tr class="text-left font-bold">
            <th class="pb-4 pt-6 px-6">ID</th>
            <th class="pb-4 pt-6 px-6">Cambio</th>
            <th class="pb-4 pt-6 px-6">Fecha</th>
          </tr>
          <tr v-for="change in changes.data" :key="change.id" class="hover:bg-gray-100 focus-within:bg-gray-100">
            <td class="border-t">
              <Link class="flex items-center px-6 py-4 focus:text-indigo-500" :href="`/taxes/${change.id}/edit`">
                {{ change.id }}
              </Link>
            </td>
            <td class="border-t">
              <Link class="flex items-center px-6 py-4 focus:text-indigo-500" :href="`/taxes/${change.id}/edit`">
                {{ change.change }}
              </Link>
            </td>
            <td class="border-t">
              <Link class="flex items-center px-6 py-4 focus:text-indigo-500" :href="`/taxes/${change.id}/edit`">
                {{ change.created_at }}
              </Link>
            </td>
          </tr>
          <tr v-if="!changes">
            <td class="px-6 py-4 border-t" colspan="4">No hay tasas de cambio.</td>
          </tr>
        </table>
      </div>
      <!-- <pagination class="mt-6" :links="vendors.links" /> -->
    </div>
  </template>
  
  <script>
  import { Head, Link } from '@inertiajs/inertia-vue3'
  import Icon from '@/Shared/Icon'
  import Layout from '@/Shared/Layout'
  
  export default {
    components: {
      Head,
      Icon,
      Link,
    },
    layout: Layout,
    props: {
      changes: Object
    },
  }
  </script>
  