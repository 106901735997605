<template>
  <Head :title="`Registrate como Representante en ${account.reasonName}`" />
  <div class="flex items-center justify-center p-10 min-h-screen bg-gray-300">
    <div class="w-full max-w-lg">
      <div class="flex justify-center">
        <logo :src="logo" class="fill-white" width="200" height="50" />
      </div>
      <form class="mt-8 bg-white rounded-lg shadow-xl overflow-hidden" @submit.prevent="register">
        <div class="px-10 py-12">
          <h1 class="text-center text-xl font-bold">Registrate como Representante</h1>
          <div class="mt-6 mx-auto w-24 border-b-2" />
          <select-input v-model="form.street_id" :error="form.errors.street_id" class="pb-8 w-full" label="Calle">
            <option value="">Seleccionar</option>
            <option v-for="street in streets" :key="street.id" :value="street.id">
              {{ street.name }}
            </option>
          </select-input>
          <text-input v-model="form.companyName" :error="form.errors.companyName" class="pb-8 w-full" label="Parcela" />
          <text-input v-model="form.name" :error="form.errors.name" class="pb-8 w-full" label="Nombre y Apellido" />
          <text-input v-model="form.email" :error="form.errors.email" class="pb-8 w-full" label="Correo electrónico" />
          <text-input v-model="form.identification" :error="form.errors.identification" class="pb-8 w-full" label="Cédula" />
          <text-input v-model="form.phone" :error="form.errors.phone" class="pb-8 w-full" label="Telefono" />
          <text-input v-model="form.address" :error="form.errors.address" class="pb-8 w-full" label="Dirección" />
        </div>
        <div class="flex px-10 py-4 bg-gray-100 border-t border-gray-100">
          <loading-button :loading="form.processing" class="btn-blue ml-auto" type="submit">Registrarme</loading-button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { Head } from '@inertiajs/inertia-vue3'
import Logo from '@/Shared/Logo'
import TextInput from '@/Shared/TextInput'
import LoadingButton from '@/Shared/LoadingButton'
import SelectInput from '../Shared/SelectInput.vue'

export default {
  components: {
    Head,
    LoadingButton,
    Logo,
    TextInput,
    SelectInput,
  },
  props: {
    streets: Object,
    account: Object,
    logo: String
  },
  data() {
    return {
      form: this.$inertia.form({
        identification: '',
        companyName: '',
        name: '',
        phone: '',
        address: '',
        street_id: '',
        email: ''
      }),
    }
  },
  methods: {
    register() {
      this.form.post('/registerClient')
    },
  },
}
</script>
