<template>
  <div class="flex items-center justify-center p-2 min-h-screen bg-gray-300">
    <div>
      <button class="mr-2 p-2 text-white bg-teal-800 rounded" @click="back()">
        <i class="fa fa-arrow-left"></i>
      </button>
      <h1 class="mb-8 text-3xl font-bold">{{ form.name }} (${{ form.balance }})</h1>

      <div class="shadow-overflow-hidden mb-3 max-w-full bg-white rounded-md">
        <div class="flex items-center px-8 py-4 text-white bg-teal-800 rounded-t-md">Lista de cuotas en proceso</div>
        <table class="w-full whitespace-nowrap">
          <tr class="text-left font-bold">
            <th class="pb-4 pt-6 px-6">#</th>
            <th class="pb-4 pt-6 px-6">Fecha</th>
            <th class="pb-4 pt-6 px-6">Estado</th>
            <th class="pb-4 pt-6 px-6">Observacion</th>
            <th class="pb-4 pt-6 px-6">Total</th>
          </tr>
          <tr v-for="order in orders" :key="order.id" :class="`hover:bg-gray-100 focus-within:bg-gray-100 ${order.order_state === 'cancel' ? 'bg-red-200' : ''}`">
            <td class="border-t">
              <Link :href="`/getOrderExternal/${order.id}`" class="flex items-center px-6 py-4 focus:text-indigo-500">
                {{ order.id }}
              </Link>
            </td>
            <td class="border-t">
              <Link :href="`/getOrderExternal/${order.id}`" class="flex items-center px-6 py-4 focus:text-indigo-500">
                {{ order.date }}
              </Link>
            </td>
            <td class="border-t">
              <Link :href="`/getOrderExternal/${order.id}`" class="flex items-center px-6 py-4 focus:text-indigo-500">
                <span v-if="order.order_state == 'cancel'">ANULADA</span>
                <span v-if="order.order_state == 'request'">PENDIENTE POR PAGO</span>
                <span v-if="order.order_state == 'finish'">FINALIZADA</span>
                <span v-if="order.order_state == 'pay'">PAGADA</span>
              </Link>
            </td>
            <td class="border-t">
              <Link :href="`/getOrderExternal/${order.id}`" class="flex items-center px-6 py-4 focus:text-indigo-500">
                {{ order.comentary }}
              </Link>
            </td>
            <td class="border-t">
              <Link :href="`/getOrderExternal/${order.id}`" class="flex items-center px-6 py-4 focus:text-indigo-500"> ${{ order.total }} </Link>
            </td>
          </tr>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import { Head, Link } from '@inertiajs/inertia-vue3'
import TextInput from '@/Shared/TextInput'
import SelectInput from '@/Shared/SelectInput'
import LoadingButton from '@/Shared/LoadingButton'
import TrashedMessage from '@/Shared/TrashedMessage'

export default {
  components: {
    Head,
    Link,
    LoadingButton,
    SelectInput,
    TextInput,
    TrashedMessage,
  },
  props: {
    client: Object,
    organizations: Array,
    orders: Array,
  },
  remember: 'form',
  data() {
    return {
      form: this.$inertia.form({
        name: this.client.name,
        email: this.client.email,
        phone: this.client.phone,
        identification: this.client.identification,
        companyName: this.client.companyName,
        address: this.client.address,
        balance: this.client.balance,
        typeClient: this.client.typeClient,
      }),
    }
  },
  methods: {
    back() {
      window.history.back()
    },
    update() {
      this.form.put(`/clients/${this.client.id}`)
    },
    destroy() {
      if (confirm('Are you sure you want to delete this client?')) {
        this.$inertia.delete(`/clients/${this.client.id}`)
      }
    },
    restore() {
      if (confirm('Are you sure you want to restore this client?')) {
        this.$inertia.put(`/clients/${this.client.id}/restore`)
      }
    },
  },
}
</script>
