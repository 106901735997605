<template>
    <div class="bg-white shadow-sm border border-slate-200 border-radius rounded-lg p-2">
        <div class="border-black">
            <h5 class="mb-2 text-slate-800 text-xl font-semibold">
                {{ title ?? 'Titulo' }}
            </h5>
        </div>
        <div>
            <slot />
        </div>
    </div>
</template>

<script>
export default {
    name: 'BoxDashboard',
    props: {
        title: String,

    }
}
</script>