<template>
  <div>
    <div class="mb-4">
      <Link class="group flex items-center py-3" href="/">
        <icon name="dashboard" class="mr-2 w-4 h-4" :class="isUrl('') ? 'fill-white' : 'fill-gray-300 group-hover:fill-white'" />
        <div :class="isUrl('') ? 'text-white' : 'text-gray-300 group-hover:text-white'">Escritorio</div>
      </Link>
    </div>
    <div class="mb-4" v-if="role == 'admin' || role == 'sales' || role == 'financial'">
      <Link class="group flex items-center py-3" href="/clients">
        <i :class="isUrl('clients') ? 'fa fa-address-book mr-2 text-white' : 'fa fa-address-book mr-2 text-gray-300 group-hover:fill-white'" />
        <div :class="isUrl('clients') ? 'text-white' : 'text-gray-400 group-hover:text-white'">Representantes</div>
      </Link>
    </div>
    <div v-if="role == 'sales' || role == 'admin' || role == 'storer' || role == 'financial'" class="mb-4">
      <Link class="group flex items-center py-3" href="/orders">
        <i :class="isUrl('orders') ? 'fa fa-shopping-cart mr-2 text-white' : 'fa fa-shopping-cart mr-2 text-gray-300 group-hover:fill-white'" />
        <div :class="isUrl('orders') ? 'text-white' : 'text-gray-400 group-hover:text-white'">Cobranzas</div>
      </Link>
    </div>
    <div v-if="role == 'admin' || role == 'financial'" class="mb-4">
      <Link class="group flex items-center py-3" href="/spents">
        <i :class="isUrl('spents') ? 'fa fa-money mr-2 text-white' : 'fa fa-money mr-2 text-gray-300 group-hover:fill-white'" />
        <div :class="isUrl('spents') ? 'text-white' : 'text-gray-400 group-hover:text-white'">Gastos</div>
      </Link>
    </div>
    <div v-if="role == 'sales' || role == 'admin'" class="mb-4">
      <Link class="group flex items-center py-3" href="/reports">
        <i :class="isUrl('reports') ? 'fa fa-file-o mr-2 text-white' : 'fa fa-file mr-2 text-gray-300 group-hover:fill-white'" />
        <div :class="isUrl('reports') ? 'text-white' : 'text-gray-400 group-hover:text-white'">Reportes</div>
      </Link>
    </div>
    <div v-if="role == 'admin' || role == 'financial'" class="mb-4">
      <Link class="group flex items-center py-3" href="/taxes">
        <i :class="isUrl('taxes') ? 'fa fa-money mr-2 text-white' : 'fa fa-money mr-2 text-gray-300 group-hover:fill-white'" />
        <div :class="isUrl('taxes') ? 'text-white' : 'text-gray-400 group-hover:text-white'">Tasas de cambio</div>
      </Link>
    </div>
  </div>
</template>

<script>
import { Link } from '@inertiajs/inertia-vue3'
import Icon from '@/Shared/Icon'

export default {
  components: {
    Icon,
    Link,
  },
  data()
  {
    return {
      role: this.$parent.auth.user.role
    }
  },
  methods: {
    back() {
      window.history.back();
    },
    isUrl(...urls) {
      let currentUrl = this.$page.url.substr(1)
      if (urls[0] === '') {
        return currentUrl === ''
      }
      return urls.filter((url) => currentUrl.startsWith(url)).length
    },
  },
}
</script>
